(() => {
  const processIdentify = urlParams => {
    const email = urlParams.get('invitee_email');
    window.HockeyStack.identify(email);

    const urlParamsArray = [...urlParams];
    window.HockeyStack.goal('Booked from Calendly', {
      calendly__assigned_to: urlParams.get('assigned_to'),
      calendly__event_name: urlParams.get('event_type_name'),
      calendly__event_uuid: urlParams.get('event_type_uuid'),
      ...Object.fromEntries(urlParamsArray.filter(([key, value]) => key.startsWith('answer_')).map(([key, value]) => (['calendly__' + key, value ])))
    });
  };

  const getData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const eventTypeUUID = urlParams.get('event_type_uuid');

    try {
      // make sure it is from calendly
      if (eventTypeUUID) {
        if (window.HockeyStack) return processIdentify(urlParams);

        if (!Array.isArray(window.hockeystackQueue)) {
          window.hockeystackQueue = [];
        }
        window.hockeystackQueue.push(() => processIdentify(urlParams));
      }
    } catch (error) {}
  };

  getData();
})();
